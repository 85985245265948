<template>
  <layout-vertical>

    <router-view/>

    <template v-slot:navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive"/>
    </template>

    <!--Верхний блок меню-->
    <template v-slot:vertical-menu-header>
      <ul class="nav navbar-nav flex-row">

        <!-- Логотип-->
        <li class="nav-item mr-auto">
          <b-link
              class="navbar-brand"
              to="/"
          >
              <span class="brand-logo">
                <b-img
                    src="@/assets/images/logo/ledLogo.svg"
                    alt="logo"
                />
              </span>
            <div v-if="isVerticalMenuActive" class="textWrapper">
              <div class="logo-text " style="font-size: 12pt">Конфигуратор</div>
            </div>
          </b-link>
        </li>

        <!-- Тоглер -->
        <ToggleButton></ToggleButton>
      </ul>
    </template>


    <!--Заполнение слота пустыми данными-->
    <div slot="breadcrumb">
      <BlBreadcrumb></BlBreadcrumb>
    </div>


    <!--футер-->
    <div slot="footer">
      <p class="mb-0">
        Все права защищены &copy; {{ new Date().getFullYear() }} <a href="">ByLed.by</a>
      </p>
    </div>

  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import Navbar from '../../components/menu/Navbar.vue'
import ByLedLogo from "@/components/core/ByLedLogo";
import {BLink, BNavbarNav, BImg} from "bootstrap-vue";
import ToggleButton from "@/components/menu/ToggleButton";
import BlBreadcrumb from "@/components/core/BlBreadcrumb";

export default {
  components: {
    BlBreadcrumb,
    ToggleButton,
    ByLedLogo,
    LayoutVertical,
    Navbar,
    BLink,
    BNavbarNav,
    BImg
  },
  data() {
    return {
      isVerticalMenuActive: true
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

.logo-text {
  color: black;
  font-size: 10px;
  font-family: 'Montserrat', sans-serif;
}

.textWrapper {
  padding-left: 14px;
}

</style>
