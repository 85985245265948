<template>
  <li class="nav-item nav-toggle">
    <b-link class="nav-link modern-nav-toggle">
      <feather-icon
          icon="XIcon"
          size="20"
          class="d-block d-xl-none"
          @click="!toggleVerticalMenuActive"
      />
      <feather-icon
          :icon="collapseTogglerIconFeather"
          size="20"
          class="d-none d-xl-block collapse-toggle-icon"
          @click="toggleCollapsed"
      />
    </b-link>
  </li>
</template>

<script>
import VerticalNavMenuItems
  from "@core/layouts/layout-vertical/components/vertical-nav-menu/components/vertical-nav-menu-items/VerticalNavMenuItems";
import {BImg, BLink} from "bootstrap-vue";
import useVerticalNavMenu from "@core/layouts/layout-vertical/components/vertical-nav-menu/useVerticalNavMenu";
import {computed, provide} from "@vue/composition-api";
import navMenuItems from "@/navigation/vertical";

export default {
name: "ToggleButton",
  components: {
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
    },
    toggleVerticalMenuActive: {
      type: Function,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)


    provide('isMouseHovered', isMouseHovered)

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    return {
      navMenuItems,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,
    }
  },
}
</script>

<style scoped>

</style>
